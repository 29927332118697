import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { withRouter } from 'react-router-dom'
import isEqual from 'lodash/isEqual'
import { getObject, pathLength, subPath } from '@adalo/utils'

import {
  LABEL,
  SECTION,
  ELLIPSE,
  SHAPE,
  IMAGE,
  GROUP,
  LIST,
  INPUT,
  IMAGE_UPLOAD,
  FILE_UPLOAD,
  DATE_PICKER,
  SELECT,
  FORM,
  WEB_VIEW,
  COMPONENT_INSTANCE,
  LIBRARY_COMPONENT,
  LINE,
  LOCATION_INPUT,
  LAYOUT_SECTION,
  COMPONENT,
  TABLE,
  borderStyles,
  VIDEO,
  SOCIAL_MEDIA_LIST,
} from '@adalo/constants'

import { scale, scaleValue } from 'utils/zoom'
import { beginDrag } from 'ducks/editor/positioning'
import { setEditingText } from 'ducks/editor/textEditing'
import { setEditingShape } from 'ducks/editor/shapeEditing'
import { getMap, getObjectList } from 'ducks/editor/objects'
import { CONTAINER_TYPES } from 'utils/positioning'
import {
  getContainerFromSection,
  getSectionFromContainer,
  isContainerSectionElement,
  isEditableSectionElement,
} from 'utils/layoutSections'

import {
  setSelection,
  setExpandSelection,
  setCanvasHover,
} from 'ducks/editor/selection'

import Label from './Label'
import Section from './Section'
import Line from './Line'
import Ellipse from './Ellipse'
import Shape from './Shape'
import Image from './Image'
import Video from './Video'
import Group from './Group'
import List from './List'
import Input from './Input'
import ImageUpload from './ImageUpload'
import FileUpload from './FileUpload'
import DatePicker from './DatePicker'
import Select from './Select'
import Form from './Form'
import WebView from './WebView'
import ComponentInstance from './ComponentInstance'
import LibraryComponent from './LibraryComponent'
import LocationInput from './LocationInput'
import CanvasObjectWrapper from './CanvasObjectWrapper'
import LayoutSection from './LayoutSection'
import Table from './Table'
import SocialMediaList from './SocialMediaList'

class CanvasObject extends Component {
  static contextTypes = {
    editable: PropTypes.bool,
    getSelection: PropTypes.func,
  }

  state = { error: null }

  handleMouseEnter = e => {
    const { object, setCanvasHover } = this.props

    const { editable } = this.context

    if (!editable) {
      return
    }

    setCanvasHover(object)
  }

  handleMouseLeave = e => {
    const { editable } = this.context
    const { object, setCanvasHover, globalState } = this.props

    if (!editable) {
      return
    }

    let parent = null

    if (object) {
      const map = getMap(globalState)
      const path = map[object.id]
      const parentPath = subPath(path, pathLength(path) - 1)
      parent = getObject(getObjectList(globalState), parentPath)

      // we don't directly select GROUPs so we need to select their "grandparents" instead.
      if (parent?.type === GROUP) {
        const groupPath = map[parent.id]
        const groupParentPath = subPath(groupPath, pathLength(groupPath) - 1)

        parent = getObject(getObjectList(globalState), groupParentPath)
      }
    }

    if (parent?.type !== COMPONENT) {
      setCanvasHover(parent)
    } else {
      setCanvasHover(null)
    }
  }

  handleSelect = (shiftKey, cmdKey) => {
    const { object, setSelection, history, match } = this.props
    const { editable } = this.context

    if (!editable) {
      return
    }

    const {
      params: { appId },
    } = match

    history.push(`/apps/${appId}/screens`)

    setSelection(object.id, shiftKey, !cmdKey, null, cmdKey)
  }

  handleExpand = () => {
    const { editable } = this.context
    const { object, setExpandSelection } = this.props

    if (!editable) {
      return
    }

    setExpandSelection(object.id)
  }

  handleEdit = () => {
    const { object, setEditingText, setEditingShape } = this.props
    const { editable } = this.context

    if (!editable) {
      return
    }

    if (object.type === LABEL) {
      setEditingText(object.id)
    }

    if (object.type === SHAPE) {
      setEditingShape(object.id)
    }
  }

  shouldComponentUpdate(newProps) {
    const { zoom } = this.props

    const criticalProps = [
      'object',
      'textEditing',
      'shapeEditing',
      'hideShadows',
      'branding',
    ]

    for (const propName of criticalProps) {
      // eslint-disable-next-line react/destructuring-assignment
      if (!isEqual(newProps[propName], this.props[propName])) {
        return true
      }
    }

    if (zoom.scale !== newProps.zoom.scale) {
      return true
    }

    return false
  }

  componentDidCatch(error, info) {
    const { object } = this.props

    // This will make it get caught by sentry
    window.setTimeout(() => {
      const newError = new Error(
        `Component Error [${object && object.type}]: ${error.message}`
      )

      newError.stack = [
        newError.stack.split('\n').slice(0, 2),
        error.stack,
      ].join('\n')

      if (window.adaloDeveloper) {
        console.error(newError)
      } else {
        throw newError
      }
    }, 0)

    this.setState({ error })
  }

  shouldHaveSectionBorder = () => {
    /**
     * @type {{
     *  object: import('utils/responsiveTypes').EditorObject
     *  globalState: import('utils/layoutSections').GlobalState
     * }}
     */
    const { object, globalState } = this.props

    if (isEditableSectionElement(object)) {
      /** @type {import('ducks/editor/types/ObjectPathMap').ObjectPathMap} */
      const map = getMap(globalState)

      /** @type {import('ducks/editor/types/ObjectList').ObjectList} */
      const list = getObjectList(globalState)

      const isContainer = isContainerSectionElement(object)
      const container = isContainer ? object : getContainerFromSection(object)
      const section = isContainer ? getSectionFromContainer(list, map, object) : object // prettier-ignore

      if (section && container) {
        const transparent =
          container.backgroundStyle === borderStyles.NONE &&
          section.backgroundStyle === borderStyles.NONE

        const noChildren = !container.children || container.children.length < 1

        return transparent && noChildren
      }
    }

    return false
  }

  render() {
    const {
      object,
      zoom,
      hideShadows,
      beginDrag,
      branding,
      deviceType,
      layout,
      magicLayout,
      component,
      hiddenParent,
      screenResizing,
      screenHeight,
      screenWidth,
    } = this.props

    const { error } = this.state

    if (error) {
      return null
    }

    const xToScale = layout ? layout.relativeX : object.x

    const [xScaled, yScaled] = scale([xToScale, object.y], zoom)
    let widthScaled = scaleValue(object.width, zoom)
    const heightScaled = scaleValue(object.height, zoom)

    if (layout?.relativeWidth && object.type !== LABEL) {
      widthScaled = '100%'
    }

    const { children, ...withoutChildren } = object

    const childProps = {
      ...withoutChildren,
      deviceType,
      xScaled,
      yScaled,
      widthScaled,
      heightScaled,
      zoom,
      hideShadows,
      branding,
      editorResizingProps: layout,
      onPosition: beginDrag,
      onExpand: this.handleExpand,
      onSelect: this.handleSelect,
      onEdit: this.handleEdit,
      object: withoutChildren,
      icon: object.icon,
      screenHeight,
      screenWidth,
      inResponsiveApp: magicLayout,
    }

    const hiddenInDevice = () => {
      if (!object.deviceVisibility) {
        return false
      }

      return !object.deviceVisibility[deviceType]
    }

    const hidden = object.hidden || hiddenInDevice(deviceType) || hiddenParent
    const nestedMagicLayoutResizing =
      CONTAINER_TYPES.includes(object.type) && screenResizing && magicLayout

    if (hidden && !nestedMagicLayoutResizing) {
      return null
    }

    const childNodes = object.children
      ? object.children.map((obj, index) => (
          <CanvasObjectWrapper
            deviceType={deviceType}
            key={obj.id || index}
            object={obj}
            zoom={zoom}
            hideShadows={hideShadows}
            branding={branding}
            parentLayout={layout}
            magicLayout={magicLayout}
            component={component}
            hiddenParent={hidden}
          />
        ))
      : null

    switch (object.type) {
      case LABEL:
        return (
          <g
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          >
            <Label {...childProps} />
          </g>
        )
      case SECTION:
        // LayoutSection Container elements render as LayoutSection
        // because they have the same styling options
        if (isContainerSectionElement(object)) {
          return (
            <g
              onMouseEnter={this.handleMouseEnter}
              onMouseLeave={this.handleMouseLeave}
            >
              <LayoutSection
                object={object}
                editorResizingProps={layout}
                {...childProps}
                shouldHaveBorder={this.shouldHaveSectionBorder}
              >
                {childNodes}
              </LayoutSection>
            </g>
          )
        }

        return (
          <g
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          >
            <Section
              object={object}
              editorResizingProps={layout}
              {...childProps}
            >
              {childNodes}
            </Section>
          </g>
        )
      case LAYOUT_SECTION:
        return (
          <g
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          >
            <LayoutSection
              object={object}
              editorResizingProps={layout}
              shouldHaveBorder={this.shouldHaveSectionBorder}
              {...childProps}
            >
              {childNodes}
            </LayoutSection>
          </g>
        )
      case TABLE:
        return (
          <g
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          >
            <Table editorResizingProps={layout} {...childProps} />
          </g>
        )
      case LINE:
        return (
          <g
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          >
            <Line {...childProps} />
          </g>
        )
      case ELLIPSE:
        return (
          <g
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          >
            <Ellipse
              object={object}
              editorResizingProps={layout}
              {...childProps}
            >
              {childNodes}
            </Ellipse>
          </g>
        )
      case SHAPE:
        return (
          <g
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          >
            <Shape {...childProps} />
          </g>
        )
      case IMAGE:
        return (
          <g
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          >
            <Image object={object} editorResizingProps={layout} {...childProps}>
              {childNodes}
            </Image>
          </g>
        )
      case VIDEO:
        return (
          <g
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          >
            <Video object={object} editorResizingProps={layout} {...childProps}>
              {childNodes}
            </Video>
          </g>
        )
      case GROUP:
        return (
          <Group object={object} editorResizingProps={layout}>
            {childNodes}
          </Group>
        )
      case LIST:
        return (
          <g
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          >
            <List {...childProps} object={object}>
              {childNodes}
            </List>
          </g>
        )
      case INPUT:
        return (
          <g
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          >
            <Input {...childProps} />
          </g>
        )
      case IMAGE_UPLOAD:
        return (
          <g
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          >
            <ImageUpload {...childProps} />
          </g>
        )
      case FILE_UPLOAD:
        return (
          <g
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          >
            <FileUpload {...childProps} />
          </g>
        )
      case DATE_PICKER:
        return (
          <g
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          >
            <DatePicker {...childProps} />
          </g>
        )
      case SELECT:
        return (
          <g
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          >
            <Select {...childProps} />
          </g>
        )
      case FORM:
        return (
          <g
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          >
            <Form {...childProps} />
          </g>
        )
      case WEB_VIEW:
        return (
          <g
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          >
            <WebView {...childProps} />
          </g>
        )
      case COMPONENT_INSTANCE:
        return (
          <g
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          >
            <ComponentInstance {...childProps} />
          </g>
        )
      case LIBRARY_COMPONENT:
        return (
          <g
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          >
            <LibraryComponent {...childProps} />
          </g>
        )
      case LOCATION_INPUT:
        return (
          <g
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          >
            <LocationInput {...childProps} />
          </g>
        )
      case SOCIAL_MEDIA_LIST:
        return (
          <g
            onMouseEnter={this.handleMouseEnter}
            onMouseLeave={this.handleMouseLeave}
          >
            <SocialMediaList {...childProps} />
          </g>
        )
    }

    return null
  }
}

const mapStateToProps = state => ({
  globalState: state,
})

const ConnectedCanvasObject = connect(mapStateToProps, {
  setExpandSelection,
  setSelection,
  setCanvasHover,
  setEditingShape,
  setEditingText,
  beginDrag,
})(CanvasObject)

export default withRouter(ConnectedCanvasObject)
