import { Binding } from '@adalo/constants'
import { normalizeColor } from 'utils/colors'
import { Branding } from 'utils/colorsTypes'
import { SocialMediaListSectionProps } from '.'
import { BottomButton } from './BottomButton'
import { IconButton } from './IconButton'

const getIcon = (
  type: string,
  icon: string | undefined,
  toggle: string | undefined
): string | undefined => {
  return type === 'icon' ? icon : toggle
}

const getIconColor = (
  type: string,
  color: string,
  toggleColor: string,
  branding: Branding
): string => {
  const value = type === 'icon' ? color : toggleColor

  return normalizeColor(value, branding)
}

const BottomButtons = (props: SocialMediaListSectionProps): JSX.Element => {
  const { branding, object, widthScaled, sectionTop, zoom } = props
  const { attributes } = object
  const { buttons, background } = attributes
  const { padding } = background
  const {
    leftIcon1Type,
    leftIcon1Color,
    leftIcon1,
    leftIcon1InactiveColor,
    leftIcon1InactiveIcon,
    leftIcon1Label,
    leftIcon2,
    enableLeftIcon2,
    leftIcon2Type,
    leftIcon2Color,
    leftIcon2InactiveColor,
    leftIcon2InactiveIcon,
    leftIcon2Label,
    rightIcon,
    enableRightIcon,
    rightIconType,
    rightIconColor,
    rightIconInactiveColor,
    rightIconInactiveIcon,
    enableBottomButton,
    bottomButtonColor,
    bottomButtonText,
    styles: {
      leftIcon1Label: leftIcon1LabelStyles,
      leftIcon2Label: leftIcon2LabelStyles,
      bottomButtonText: bottomButtonTextStyles,
    },
  } = buttons

  let leftIconLabelLength = leftIcon1Label?.length || 0
  const iconSize = 40 * zoom.scale
  const iconFontSize = 20 * zoom.scale
  const leftIcon1X = (4 + padding) * zoom.scale

  if (leftIcon1Label && typeof leftIcon1Label === 'object') {
    leftIconLabelLength = leftIcon1Label.reduce(
      (total: number, current: string | Binding): number => {
        if (typeof current === 'string') {
          return total + current.length
        }

        return total + current.label.length
      },
      4
    )
  }

  const leftIcon2X = leftIcon1Label
    ? (28 + 2 * padding) * zoom.scale + leftIconLabelLength * 4
    : (28 + 2 * padding) * zoom.scale

  const leftIcon1Rendered = getIcon(
    leftIcon1Type,
    leftIcon1,
    leftIcon1InactiveIcon
  )
  const leftIcon1RenderedColor = getIconColor(
    leftIcon1Type,
    leftIcon1Color,
    leftIcon1InactiveColor,
    branding
  )
  const leftIcon2Rendered = getIcon(
    leftIcon2Type,
    leftIcon2,
    leftIcon2InactiveIcon
  )
  const leftIcon2RenderedColor = getIconColor(
    leftIcon2Type,
    leftIcon2Color,
    leftIcon2InactiveColor,
    branding
  )
  const rightIconRendered = getIcon(
    rightIconType,
    rightIcon,
    rightIconInactiveIcon
  )
  const rightIconRenderedColor = getIconColor(
    rightIconType,
    rightIconColor,
    rightIconInactiveColor,
    branding
  )

  return (
    <>
      <IconButton
        rendered
        iconRendered={leftIcon1Rendered}
        iconSize={iconSize}
        iconFontSize={iconFontSize}
        iconColor={leftIcon1RenderedColor}
        zoom={zoom}
        branding={branding}
        id={object.id}
        x={leftIcon1X}
        sectionTop={sectionTop}
        fontStyles={leftIcon1LabelStyles}
        label={leftIcon1Label}
      />
      <IconButton
        rendered={enableLeftIcon2}
        iconRendered={leftIcon2Rendered}
        iconSize={iconSize}
        iconFontSize={iconFontSize}
        iconColor={leftIcon2RenderedColor}
        zoom={zoom}
        branding={branding}
        id={object.id}
        x={leftIcon2X}
        sectionTop={sectionTop}
        fontStyles={leftIcon2LabelStyles}
        label={leftIcon2Label}
      />
      <IconButton
        rendered={enableRightIcon}
        iconRendered={rightIconRendered}
        iconSize={iconSize}
        iconFontSize={iconFontSize}
        iconColor={rightIconRenderedColor}
        zoom={zoom}
        branding={branding}
        id={object.id}
        x={widthScaled - (34 + padding) * zoom.scale}
        sectionTop={sectionTop}
      />
      <BottomButton
        enableBottomButton={enableBottomButton}
        widthScaled={widthScaled}
        zoom={zoom}
        branding={branding}
        id={object.id}
        sectionTop={sectionTop}
        padding={padding}
        bottomButtonColor={bottomButtonColor}
        bottomButtonTextStyles={bottomButtonTextStyles}
        bottomButtonText={bottomButtonText}
      />
    </>
  )
}

export default BottomButtons
