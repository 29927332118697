import { backgroundStyle } from '@adalo/constants'
import BindingValue from 'components/Shared/BindingValue'
import { isFirefox } from 'utils/browsers'
import { normalizeColor } from 'utils/colors'
import { getFontFamily } from 'utils/type'
import { scaleValue } from 'utils/zoom'
import { SocialMediaListProps } from '.'
import Image from '../Image'

export const Header = (props: SocialMediaListProps): JSX.Element => {
  const { widthScaled, object, zoom, branding } = props
  const { attributes } = object
  const { background, header } = attributes
  const { padding = 6 } = background
  const {
    profilePicture,
    text1,
    text2,
    text3,
    rightIcon1,
    rightIcon2,
    rightIcon1Enabled,
    rightIcon2Enabled,
    rightIcon1Color,
    rightIcon2Color,
    styles: headerStyles,
  } = header
  const {
    text1: text1Styles,
    text2: text2Styles,
    text3: text3Styles,
  } = headerStyles

  const iconFontSize = 20 * zoom.scale
  const headerTextX = (44 + 2 * padding) * zoom.scale
  const listItemPadding = (4 + padding) * zoom.scale

  const headerCenter = (24 + padding) * zoom.scale
  let headerTextHeight = text1 ? text1Styles.fontSize * zoom.scale : 0

  if (text2) {
    headerTextHeight += text2Styles.fontSize * zoom.scale
  }

  if (text3) {
    headerTextHeight += text3Styles.fontSize * zoom.scale
  }

  const text1PaddingTop = headerCenter - headerTextHeight / 2
  const text2PaddingTop = text1
    ? text1PaddingTop + text1Styles.fontSize * zoom.scale
    : text1PaddingTop
  const text3PaddingTop = text2
    ? text2PaddingTop + text2Styles.fontSize * zoom.scale
    : text2PaddingTop

  const rightIconX = rightIcon2Enabled
    ? widthScaled - (66 + 2 * padding) * zoom.scale
    : widthScaled - (34 + padding) * zoom.scale

  return (
    <>
      <Image
        {...props}
        {...(profilePicture || {})}
        id={`${object.id}-profile-picture`}
        object={object}
        width={40}
        height={40}
        widthScaled={scaleValue(40, zoom)}
        heightScaled={scaleValue(40, zoom)}
        xScaled={listItemPadding}
        yScaled={listItemPadding}
        borderRadius={20}
        opacity={1}
        backgroundStyle={backgroundStyle.COLOR}
        backgroundColor="#e0e0e0"
      />
      {text1 && (
        <text
          x={0}
          y={text1PaddingTop}
          fill={normalizeColor(text1Styles.color, branding)}
          fontSize={text1Styles.fontSize * zoom.scale}
          fontWeight={text1Styles.fontWeight}
          clipPath={`url(#header-text1-path-${object.id})`}
          overflow="hidden"
          width={200}
        >
          <tspan
            alignmentBaseline="text-before-edge"
            x={headerTextX}
            y={text1PaddingTop + (isFirefox() ? 8 : 0)}
            style={{
              fontFamily: getFontFamily(text1Styles.fontFamily, branding),
            }}
            overflow="hidden"
            width={50}
          >
            <BindingValue binding={text1} objectId={object.id} />
          </tspan>
        </text>
      )}
      {text2 && (
        <text
          x={0}
          y={text2PaddingTop}
          fill={normalizeColor(text2Styles.color, branding)}
          fontSize={text2Styles.fontSize * zoom.scale}
          fontWeight={text2Styles.fontWeight}
          clipPath={`url(#header-text2-path-${object.id})`}
        >
          <tspan
            alignmentBaseline="text-before-edge"
            x={headerTextX}
            y={text2PaddingTop + (isFirefox() ? 5 : 0)}
            style={{
              fontFamily: getFontFamily(text2Styles.fontFamily, branding),
            }}
          >
            <BindingValue binding={text2} objectId={object.id} />
          </tspan>
        </text>
      )}
      {text3 && (
        <text
          x={0}
          y={text3PaddingTop}
          fill={normalizeColor(text3Styles.color, branding)}
          fontSize={text3Styles.fontSize * zoom.scale}
          fontWeight={text3Styles.fontWeight}
          clipPath={`url(#header-text3-path-${object.id})`}
        >
          <tspan
            alignmentBaseline="text-before-edge"
            x={headerTextX}
            y={text3PaddingTop + (isFirefox() ? 5 : 0)}
            style={{
              fontFamily: getFontFamily(text3Styles.fontFamily, branding),
            }}
          >
            <BindingValue binding={text3} objectId={object.id} />
          </tspan>
        </text>
      )}
      {rightIcon1Enabled && rightIcon1 ? (
        <foreignObject
          x={rightIconX}
          y={(14 + padding) * zoom.scale}
          height={iconFontSize}
          width={iconFontSize}
        >
          <i
            className="material-icons"
            style={{
              color: normalizeColor(rightIcon1Color, branding),
              fontSize: iconFontSize,
              display: 'block',
            }}
          >
            {rightIcon1.replace(/-/g, '_')}
          </i>
        </foreignObject>
      ) : null}
      {rightIcon2Enabled && rightIcon2 ? (
        <foreignObject
          x={widthScaled - (34 + padding) * zoom.scale}
          y={(14 + padding) * zoom.scale}
          height={iconFontSize}
          width={iconFontSize}
        >
          <i
            className="material-icons"
            style={{
              color: normalizeColor(rightIcon2Color, branding),
              fontSize: iconFontSize,
              display: 'block',
            }}
          >
            {rightIcon2.replace(/-/g, '_')}
          </i>
        </foreignObject>
      ) : null}
    </>
  )
}
