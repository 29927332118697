import { Zoom } from 'utils/canvasTypes'
import { SocialMediaListObject } from 'utils/responsiveTypes'
import { scaleValue } from 'utils/zoom'
import { backgroundStyle } from '@adalo/constants'
import { normalizeColor } from 'utils/colors'
import { Branding } from 'utils/colorsTypes'
import { isFirefox } from 'utils/browsers'
import { getFontFamily } from 'utils/type'
import BindingValue from 'components/Shared/BindingValue'
import GroupWrapper from '../Group/GroupWrapper'
import Image from '../Image'
import Rect from '../Section/Rect'
import BottomText from './BottomText'
import BottomButtons from './BottomButtons'
import { Header } from './Header'

export interface SocialMediaListProps {
  xScaled: number
  yScaled: number
  widthScaled: number
  heightScaled: number
  zoom: Zoom
  editorResizingProps: Record<string, unknown>
  object: SocialMediaListObject
  branding: Branding
  onSelect: (arg1: unknown, arg2: unknown) => void
  onPosition: (arg1: unknown) => void
  deviceType: 'mobile' | 'tablet' | 'desktop'
}

export interface SocialMediaListSectionProps extends SocialMediaListProps {
  sectionTop: number
}

const SocialMediaList = (props: SocialMediaListProps): JSX.Element => {
  const {
    xScaled,
    yScaled,
    widthScaled,
    object,
    zoom,
    editorResizingProps,
    branding,
    onSelect,
    deviceType,
  } = props
  const { attributes, width: objectWidth } = object
  const { background, content, bottomText, buttons } = attributes
  const {
    color,
    borderWidth,
    borderColor,
    borderRadius,
    border,
    padding = 6,
  } = background
  const { topCaption, contentImage, styles: contentStyles } = content
  const { topCaption: topCaptionStyles } = contentStyles
  const { enabled: bottomTextEnabled, styles: bottomTextStyles } = bottomText
  const { enableBottomButton } = buttons

  const width = object[deviceType]?.width || objectWidth

  const defaultRectProps = {
    width,
    zoom,
    widthScaled,
    borderRadius,
    borderTopLeftRadius: borderRadius,
    borderTopRightRadius: borderRadius,
    borderBottomLeftRadius: borderRadius,
    borderBottomRightRadius: borderRadius,
  }

  let borderProps = {
    strokeWidth: 0,
    stroke: 'transparent',
  }

  if (border) {
    borderProps = {
      strokeWidth: borderWidth,
      stroke: normalizeColor(borderColor, branding),
    }
  }

  const listItemPadding = 4 + padding

  const headerHeight = (38 + 2 * padding) * zoom.scale
  const contentTop =
    headerHeight + (topCaptionStyles.fontSize + 18 + padding) * zoom.scale
  const contentHeight = (width * 3) / 4
  const bottomTextTop = contentTop + (contentHeight + 6 + padding) * zoom.scale

  const bottomTextMaxHeight = Math.max(
    bottomTextStyles.leftText.fontSize,
    bottomTextStyles.rightText.fontSize
  )
  const buttonsTop = bottomTextEnabled
    ? bottomTextTop + (bottomTextMaxHeight + 9 + padding) * zoom.scale
    : bottomTextTop

  let listItemHeight = buttonsTop + (28 + padding) * zoom.scale

  if (enableBottomButton) {
    listItemHeight += 25 + padding * zoom.scale
  }

  const handleSelect = (e: React.MouseEvent): void => {
    e.stopPropagation()
    onSelect(e.shiftKey, e.metaKey)
  }

  const handleMouseDown = (e: React.MouseEvent): void => {
    const { onPosition } = props

    handleSelect(e)

    const point = [e.clientX, e.clientY]
    onPosition(point)
  }

  return (
    <GroupWrapper object={object} editorResizingProps={editorResizingProps}>
      <g
        transform={`translate(${xScaled}, ${yScaled})`}
        onMouseDown={handleMouseDown}
      >
        <Rect
          {...defaultRectProps}
          {...borderProps}
          x={0}
          y={0}
          height={listItemHeight}
          heightScaled={listItemHeight}
          fill={normalizeColor(color, branding)}
          fillOpacity={1}
          className="social-media-list-background"
        />
        <Header {...props} />
        {topCaption && (
          <text
            x={0}
            y={headerHeight + 8.5 - topCaptionStyles.fontSize / 4}
            fill={normalizeColor(topCaptionStyles.color, branding)}
            fontSize={topCaptionStyles.fontSize * zoom.scale}
            fontWeight={topCaptionStyles.fontWeight}
            clipPath={`url(#top-caption-path-${object.id})`}
          >
            <tspan
              alignmentBaseline="text-before-edge"
              x={listItemPadding * zoom.scale}
              y={headerHeight + 10 * zoom.scale + (isFirefox() ? 7 : 0)}
              style={{
                fontFamily: getFontFamily(
                  topCaptionStyles.fontFamily,
                  branding
                ),
              }}
            >
              <BindingValue binding={topCaption} objectId={object.id} />
            </tspan>
          </text>
        )}
        <Image
          {...props}
          {...(contentImage || {})}
          id={`${object.id}-content-image`}
          width={width}
          height={contentHeight}
          heightScaled={scaleValue(contentHeight, zoom)}
          widthScaled={widthScaled - borderWidth}
          xScaled={0 + borderWidth * zoom.scale}
          yScaled={contentTop}
          borderRadius={0}
          opacity={1}
          backgroundStyle={backgroundStyle.COLOR}
          backgroundColor="#e0e0e0"
        />
        <BottomText {...props} sectionTop={bottomTextTop} />
        <BottomButtons {...props} sectionTop={buttonsTop} />
      </g>
    </GroupWrapper>
  )
}

export default SocialMediaList
