import { Binding } from '@adalo/constants'
import BindingValue from 'components/Shared/BindingValue'
import { isFirefox } from 'utils/browsers'
import { Zoom } from 'utils/canvasTypes'
import { normalizeColor } from 'utils/colors'
import { Branding } from 'utils/colorsTypes'
import { getFontFamily } from 'utils/type'

type IconButtonProps = {
  rendered: boolean
  iconRendered?: string | undefined
  iconSize: number
  iconFontSize: number
  iconColor: string
  zoom: Zoom
  branding: Branding
  id: string
  x: number
  sectionTop: number
  label?: string | [string | Binding] | undefined
  fontStyles?: {
    color: string
    fontSize: number
    fontWeight: number
    fontFamily: string
  }
}

export const IconButton = (props: IconButtonProps): JSX.Element => {
  const {
    rendered,
    iconRendered,
    iconSize,
    iconFontSize,
    iconColor,
    zoom,
    branding,
    id,
    x,
    sectionTop,
    label,
    fontStyles,
  } = props

  const {
    color = '@text',
    fontSize = 14,
    fontWeight = 400,
    fontFamily = 'inherit',
  } = fontStyles || {}

  if (!rendered) {
    return <></>
  }

  return (
    <>
      {iconRendered ? (
        <foreignObject x={x} y={sectionTop} height={iconSize} width={iconSize}>
          <i
            className="material-icons"
            style={{
              color: iconColor,
              fontSize: iconFontSize,
              display: 'block',
            }}
          >
            {iconRendered.replace(/-/g, '_')}
          </i>
        </foreignObject>
      ) : null}
      {label ? (
        <text
          x={0}
          y={0}
          fill={normalizeColor(color, branding)}
          fontSize={fontSize * zoom.scale}
          fontWeight={fontWeight}
          clipPath={`url(#bottom-caption-path-${id})`}
        >
          <tspan
            alignmentBaseline="text-before-edge"
            x={x + 12}
            y={sectionTop + 3.5 - fontSize / 4 + (isFirefox() ? 8 : 1)}
            style={{
              fontFamily: getFontFamily(fontFamily, branding),
            }}
          >
            <BindingValue binding={label} objectId={id} />
          </tspan>
        </text>
      ) : null}
    </>
  )
}
