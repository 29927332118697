import React, { Component } from 'react'
import { sort } from '@adalo/utils'

import {
  LABEL,
  SECTION,
  ELLIPSE,
  SHAPE,
  IMAGE,
  GROUP,
  INPUT,
  IMAGE_UPLOAD,
  FILE_UPLOAD,
  DATE_PICKER,
  SELECT,
  WEB_VIEW,
  COMPONENT_INSTANCE,
  LINE,
  LAYOUT_SECTION,
  VIDEO,
  TABLE,
  SOCIAL_MEDIA_LIST,
} from '@adalo/constants'

import { DEFAULT_ZOOM } from '../../../../utils/zoom'
import Label from '../Label'
import Section from '../Section'
import Line from '../Line'
import Ellipse from '../Ellipse'
import Shape from '../Shape'
import Image from '../Image'
import Video from '../Video'
import Group from '../Group'
import Input from '../Input'
import ImageUpload from '../ImageUpload'
import FileUpload from '../FileUpload'
import DatePicker from '../DatePicker'
import Select from '../Select'
import WebView from '../WebView'
import ComponentInstance from './index'
import GenericWrapper from './GenericWrapper'
import LayoutSection from '../LayoutSection'
import Table from '../Table'
import SocialMediaList from '../SocialMediaList'

const noop = () => {}

export default class ComponentRenderer extends Component {
  getClassMapping = () => {
    const classMapping = {
      [LABEL]: Label,
      [SECTION]: Section,
      [LINE]: Line,
      [ELLIPSE]: Ellipse,
      [SHAPE]: Shape,
      [IMAGE]: Image,
      [VIDEO]: Video,
      [GROUP]: Group,
      [INPUT]: Input,
      [IMAGE_UPLOAD]: ImageUpload,
      [FILE_UPLOAD]: FileUpload,
      [DATE_PICKER]: DatePicker,
      [SELECT]: Select,
      [WEB_VIEW]: WebView,
      [COMPONENT_INSTANCE]: ComponentInstance,
      [LAYOUT_SECTION]: LayoutSection,
      [TABLE]: Table,
      [SOCIAL_MEDIA_LIST]: SocialMediaList,
    }

    return classMapping
  }

  renderChildren() {
    const { object, layout, hideShadows } = this.props

    if (!object.children) {
      return null
    }

    const children = sort(object.children, child => child.layout.zIndex)

    // Render the children
    return children.map(child => (
      <ComponentRenderer
        key={child.id}
        object={child}
        layout={layout}
        hideShadows={hideShadows}
      />
    ))
  }

  render() {
    let { object, layout, hideShadows } = this.props

    const objectLayout = layout[object.id]
    const ComponentClass = this.getClassMapping()[object.type] || GenericWrapper

    if (!objectLayout || !ComponentClass) {
      return null
    }

    object = {
      ...object,
      ...object.attributes,
      x: objectLayout.left,
      y: objectLayout.top,
      width: objectLayout.width,
      height: objectLayout.height,
    }

    delete object.layoutText

    const { children, ...withoutChildren } = object

    const childProps = {
      ...withoutChildren,
      xScaled: object.x,
      yScaled: object.y,
      widthScaled: object.width,
      heightScaled: object.height,
      zoom: DEFAULT_ZOOM,
      hideShadows,
      onPosition: noop,
      onExpand: noop,
      onSelect: noop,
      onEdit: noop,
    }

    return (
      <ComponentClass {...childProps}>{this.renderChildren()}</ComponentClass>
    )
  }
}
