import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { LIBRARY_COMPONENT, SOCIAL_MEDIA_LIST } from '@adalo/constants'

//utils
import { isRole } from 'utils/libraries'

// ducks
import { getComponent, selectObject } from 'ducks/editor/objects'

// components
import BindableTextControl from 'components/Shared/BindableTextControl'
import SimpleTextControl from 'components/Editor/Inspect/Libraries/SimpleTextControl'
import Tooltip from 'components/Shared/Tooltip'
import Icon from 'components/Shared/Icon'

import MagicText from './MagicText'
import EditStyles from './EditStyles'

// stylesheet
import './EntityTextControl.scss'

const getBinding = props => {
  const { object, role, reference, disableCurrentRecord } = props

  if (disableCurrentRecord === false) {
    if (isRole(role, 'listItem') && reference) {
      return reference
    }

    if (object?.dataBinding) {
      return object.dataBinding
    }
  }

  return null
}

const EntityTextControl = props => {
  const {
    disableMagicText,
    libraryEditStyles,
    displayName,
    disableCurrentRecord,
    treatAsLibraryComponent,
    ...rest
  } = props

  const { objectId, actionId, onChange, values, name } = props

  const { appId } = useParams()

  // selectors
  const component = useSelector(state => getComponent(state, objectId))
  const object = useSelector(state => selectObject(state, objectId))

  const binding = getBinding(props)

  const textareaRef = useRef(null)

  const hideBottomBar =
    disableMagicText &&
    !(object.type !== LIBRARY_COMPONENT || libraryEditStyles)

  if (hideBottomBar) {
    return (
      <SimpleTextControl
        {...rest}
        displayName={displayName}
        hideDisplayName={!displayName}
      />
    )
  }

  const renderHeader = () => {
    if (!displayName) {
      return null
    }

    if (!disableMagicText) {
      return (
        <div className="tooltip-header">
          <p>{displayName}</p>
          <Tooltip
            tooltip={
              <>
                Use Magic Text to pull information directly from your Adalo
                Database dynamically. {'  '}
                <a
                  href="https://help.adalo.com/component-basics/inserting-dynamic-text"
                  target="_blank"
                  rel="noreferrer"
                >
                  Learn more.
                </a>
              </>
            }
            hideArrow
          >
            <Icon type="help-small" />
          </Tooltip>
        </div>
      )
    }

    return <p>{displayName}</p>
  }

  return (
    <div className="entity-text-control-wrapper">
      {renderHeader()}
      <div className="entity-text-control">
        <BindableTextControl
          {...rest}
          childRef={textareaRef}
          hideBinding
          hideDisplayName
        />

        <div className="entity-text-control-options">
          {!disableMagicText ? (
            <Tooltip
              small
              hideArrow
              tooltip="Add Magic Text"
              placement="bottom"
              className="entity-text-control-tooltip"
            >
              <div className="entity-text-control-option">
                <MagicText
                  ref={textareaRef}
                  binding={binding}
                  componentId={component.id}
                  objectId={object.id}
                  appId={appId}
                  actionId={actionId}
                  inputName={name}
                />
              </div>
            </Tooltip>
          ) : null}
          {![LIBRARY_COMPONENT, SOCIAL_MEDIA_LIST].includes(object.type) ||
          libraryEditStyles ? (
            <div className="entity-text-control-option">
              <EditStyles
                onChange={onChange}
                object={object}
                values={values}
                enabledStyles={libraryEditStyles}
                name={name}
                treatAsLibraryComponent={treatAsLibraryComponent}
              />
            </div>
          ) : null}
        </div>
      </div>
    </div>
  )
}

EntityTextControl.propTypes = {
  displayName: PropTypes.string,
  disableMagicText: PropTypes.bool,
  name: PropTypes.string,
  objectId: PropTypes.string,
  placeholder: PropTypes.string,
  reference: PropTypes.string,
  role: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.array,
    PropTypes.object,
  ]),
}

EntityTextControl.defaultProps = {
  placeholder: 'Enter Text...',
}

export default EntityTextControl
