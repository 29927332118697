import { updateObject } from 'ducks/editor/objects'
import { connect } from 'react-redux'
import { openAccordion, closeAccordion } from 'ducks/accordions'
import React from 'react'
import { SocialMediaListObject } from 'utils/responsiveTypes'
import { evaluateEnabled } from 'utils/libraries'
import { deepMerge } from '@adalo/utils'
import info from './socialMediaListManifest.json'
import { LibraryInspectItem } from './Libraries'

interface SocialMediaListProps {
  object: SocialMediaListObject
  appId: string
  mobileOnly: boolean
  updateObject: (id: string, changes: Record<string, unknown>) => void
  resizeObject: () => void
}

const SocialMediaListInspect = (props: SocialMediaListProps) => {
  const { object, appId, mobileOnly, resizeObject } = props

  const handleChange = (
    changes: Record<string, unknown>,
    actionChanges = {}
  ): void => {
    // eslint-disable-next-line @typescript-eslint/no-shadow
    const { updateObject } = props

    const newObject = deepMerge(
      object.attributes,
      changes
    ) as SocialMediaListObject['attributes']

    updateObject(object.id, { attributes: newObject, actions: actionChanges })
  }

  return (
    <React.Fragment key={object.id}>
      <LibraryInspectItem
        values={object.attributes}
        config={info}
        onChange={handleChange}
        object={object}
        isOnlyItem={(info.childComponents || []).length === 0}
        objectWidth={object.width}
        objectHeight={object.height}
        resizeObject={resizeObject}
        mobileOnly={mobileOnly}
        appId={appId}
      />
      {(info.childComponents || []).map(
        child =>
          evaluateEnabled(child.enabled, object.attributes) && (
            <LibraryInspectItem
              key={child.name}
              config={child}
              values={object.attributes && object.attributes[child.name]}
              onChange={handleChange}
              namespace={child.name}
              object={object}
              objectWidth={object.width}
              objectHeight={object.height}
              resizeObject={resizeObject}
              mobileOnly={mobileOnly}
              appId={appId}
              canBeUploaded
              treatAsLibraryComponent
            />
          )
      )}
    </React.Fragment>
  )
}

const mapStateToProps = () => ({})

const mapDispatchToProps = {
  updateObject,
  closeAccordion,
  openAccordion,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SocialMediaListInspect)
