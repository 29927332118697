import React, { Component } from 'react'
import { connect } from 'react-redux'
import { IMAGE, dataTypes, imageResize, imageTypes } from '@adalo/constants'

import { getLabel } from 'utils/sources'
import { isRole } from 'utils/libraries'
import {
  getCurrentAppId,
  getComponent,
  selectObjects,
} from 'ducks/editor/objects'

import {
  getLibraryBindingSuggestions,
  getListChildBindings,
} from 'ducks/recommender'

import { getSelection } from 'ducks/editor/selection'
import { getDatasources } from 'ducks/apps/datasources'
import { isEditableSectionElement } from 'utils/layoutSections'

import IconOption from 'components/Shared/IconOption'
import ImageInput from 'components/Shared/Forms/ImageInput'
import BindableTextControl from 'components/Shared/BindableTextControl'
import { THEMES } from '../../../../constants'

import MenuControl from './MenuControl'

import './FileAndImageControl.css'

const imageResizeOptions = [
  { label: 'Crop Image to Fill Space', value: imageResize.COVER },
  { label: 'Show Full Image (Don’t Crop)', value: imageResize.CONTAIN },
]

const imagePlaceholderOptions = [
  { label: "Don't show anything", value: false },
  { label: 'Show a placeholder image', value: true },
]

class ImageControl extends Component {
  getLabel = () => {
    const { label, value } = this.props

    if (this.isLibraryComponent() && value?.imageType === 'url') {
      return <IconOption icon="link" label="URL" />
    }

    return label
  }

  isLibraryComponent = () => {
    const { object, treatAsLibraryComponent } = this.props

    return object.type === 'libraryComponent' || treatAsLibraryComponent
  }

  getMenuOptions = () => {
    const { options, canBeUploaded } = this.props

    if (canBeUploaded) {
      return [
        {
          label: <IconOption icon="uploaded-image" label="Upload" />,
          value: 'uploaded',
        },
        {
          label: (
            <IconOption
              icon="dynamic-image"
              label="Database"
              className="image-control-dynamic-image-option"
            />
          ),
          children: options,
        },
        {
          label: <IconOption icon="link" label="URL" />,
          value: 'url',
        },
      ]
    }

    if (this.isLibraryComponent()) {
      return [
        {
          label: <IconOption icon="uploaded-image" label="Upload" />,
          value: imageTypes.UPLOADED,
        },
        {
          label: (
            <IconOption
              icon="dynamic-image"
              label="Database"
              className="image-control-dynamic-image-option"
            />
          ),
          children: options,
        },
        {
          label: <IconOption icon="link" label="URL" />,
          value: imageTypes.URL,
        },
      ]
    }

    return options
  }

  getType = () => {
    const { role } = this.props

    return role && isRole(role, 'listItem')
  }

  getTarget = () => {
    const { namespace, object, treatAsLibraryComponent } = this.props

    if (object?.type === 'libraryComponent' || treatAsLibraryComponent) {
      if (namespace) {
        return object.attributes[namespace]
      } else {
        return object.attributes
      }
    } else {
      return object
    }
  }

  getImageKey = () => {
    const { object, name } = this.props

    if (name && object.type !== IMAGE && !isEditableSectionElement(object)) {
      return name
    }

    return 'imageBinding'
  }

  getImageBinding = () => {
    const target = this.getTarget()
    const key = this.getImageKey()

    if (!target) return null

    return target?.[key]
  }

  getImageOptions = () => {
    const object = this.getImageBinding()

    return object?.options
  }

  getPlaceholderStatus = () => {
    let status = ''
    const options = this.getImageOptions()

    if (options && 'placeholderImageEnabled' in options) {
      const { placeholderImageEnabled } = options

      if (typeof placeholderImageEnabled !== 'undefined') {
        status = placeholderImageEnabled
      }
    }

    return status
  }

  getImageFileName = () => {
    const { object, value } = this.props
    const options = this.getImageOptions()

    if (value?.imageType === imageTypes.UPLOADED) return value.filename1x
    if (options?.placeholderImage) return options.placeholderImage
    if (object?.imageType === imageTypes.UPLOADED) return object.filename1x

    return null
  }

  handleChange = newValues => {
    const { onChange } = this.props
    const key = this.getImageKey()

    if (!this.isLibraryComponent()) {
      // check if the newValue contains one of these properties
      const filename1x = 'filename1x' in newValues
      const imageResize = 'imageResize' in newValues
      // if the new value is for cropping or a static image we want to
      // update the root of the object instead of where the binding takes place
      if (imageResize || filename1x) return onChange(newValues)
    }

    return onChange({ [key]: newValues })
  }

  handleImageChange = (url, filename) => {
    const { object, value: libraryValue } = this.props
    let { imageType } = object
    const target = this.getImageBinding()

    if (this.isLibraryComponent()) {
      imageType = libraryValue?.imageType
    }

    if (!url) {
      const isPlaceholderImage = this.getPlaceholderStatus() === true

      if (isPlaceholderImage) {
        return this.handleChange({
          ...target,
          options: { ...target?.options, placeholderImage: null },
        })
      }

      return this.handleChange({ filename1x: url })
    }

    switch (imageType) {
      case imageTypes.UPLOADED:
        return this.handleChange({ filename1x: { url, filename } })
      default:
        return this.handleChange({
          ...target,
          options: { ...target?.options, placeholderImage: url },
        })
    }
  }

  handlePlaceholderControllerChange = value => {
    const target = this.getImageBinding()
    this.handleChange({ ...target, options: { ...target?.options, ...value } })
  }

  renderPlaceholderController = () => {
    const { allowPlaceholder } = this.props

    if (allowPlaceholder === false) {
      return null
    }

    const value = this.getPlaceholderStatus()

    return (
      <MenuControl
        displayName="If there's no image..."
        name="placeholderImageEnabled"
        value={value}
        onChange={this.handlePlaceholderControllerChange}
        options={imagePlaceholderOptions}
      />
    )
  }

  renderImageInput = () => {
    const { appId, role, value } = this.props
    const object = this.getTarget()
    const { imageType } = object

    let displayName = ''
    const fileName = this.getImageFileName()
    const name = ''

    if (
      imageType === 'dynamic' ||
      (role &&
        isRole(role, 'listItem') &&
        value.imageType !== imageTypes.UPLOADED)
    ) {
      displayName = 'Placeholder Image'
    }

    return (
      <ImageInput
        isAsset
        displayName={displayName}
        buttons={['view', 'remove']}
        appId={appId}
        input={{
          value: fileName,
          name,
          onChange: this.handleImageChange,
        }}
      />
    )
  }

  handleImageURLChange = ({ url: imageURL }) => {
    const { onChange, value: oldValue, name } = this.props

    if (this.isLibraryComponent()) {
      const newVal = { ...oldValue, binding: imageURL }

      return onChange({ [name]: newVal })
    } else {
      return onChange({ imageURL })
    }
  }

  renderLibraryImageURLInput = () => {
    const { object, role, reference, value } = this.props
    const menuValue = value?.binding

    return (
      <BindableTextControl
        displayName="URL"
        name="url"
        onChange={this.handleImageURLChange}
        value={menuValue}
        objectId={object?.id}
        placeholder="https://example.com/image.jpg"
        role={role}
        reference={reference}
      />
    )
  }

  renderImageURLInput = () => {
    const { object } = this.props

    return (
      <BindableTextControl
        displayName="URL"
        name="url"
        onChange={this.handleImageURLChange}
        value={object?.imageURL || ''}
        objectId={object?.id}
        placeholder="https://example.com/image.jpg"
      />
    )
  }

  renderImageCropping = () => {
    const { object, allowImageCropping } = this.props

    if (allowImageCropping === false) {
      return null
    }

    const value = object?.imageResize || ''

    return (
      <MenuControl
        autoSelect
        displayName="Image Cropping"
        name="imageResize"
        value={value}
        onChange={this.handleChange}
        options={imageResizeOptions}
      />
    )
  }

  handleImageSourceChange = val => {
    const { onChange, name, value: oldValue } = this.props

    if (this.isLibraryComponent()) {
      const imageType =
        typeof val[name] === 'string' ? val[name] : imageTypes.INTERNAL

      let binding

      if (imageType === imageTypes.INTERNAL) {
        binding = val[name]
      } else {
        if (oldValue?.imageType) {
          binding = oldValue?.binding
        } else if (oldValue?.type === 'binding') {
          binding = oldValue
        }
      }

      const newVal = {
        binding,
        imageType,
        type: 'imageBinding',
      }

      return onChange({ [name]: newVal })
    } else {
      return onChange(val)
    }
  }

  render() {
    const {
      name,
      displayName,
      value,
      object,
      canBeUploaded,
      role,
      filename1x: libraryFilename1x,
    } = this.props

    let { imageType, filename1x } = object
    const { imageType: libraryImageType } = value || {}
    const options = this.getMenuOptions()
    const status = this.getPlaceholderStatus()

    if (libraryFilename1x) filename1x = libraryFilename1x

    const rowHeight = canBeUploaded ? 40 : undefined

    const canCrop = imageType === 'dynamic' || filename1x || imageType === 'url'

    const hasImage =
      imageType === 'dynamic' ||
      imageType === 'url' ||
      libraryImageType === imageTypes.URL ||
      libraryImageType === imageTypes.INTERNAL ||
      (role &&
        isRole(role, 'listItem') &&
        libraryImageType !== imageTypes.UPLOADED)

    let menuValue = value?.type === 'imageBinding' ? value.binding : value

    if (value?.imageType === imageTypes.URL) {
      menuValue = imageTypes.URL
    } else if (value?.imageType === imageTypes.UPLOADED) {
      menuValue = imageTypes.UPLOADED
    }

    if (
      menuValue &&
      Object.keys(menuValue).length === 0 &&
      menuValue.constructor === Object
    ) {
      menuValue = null
    }

    return (
      <div className="library-image-control">
        <MenuControl
          options={options}
          name={name}
          displayName={displayName}
          value={menuValue}
          onChange={this.handleImageSourceChange}
          getLabel={this.getLabel}
          rowHeight={rowHeight}
          menuTheme={THEMES.DATA}
        />

        {imageType === 'url' && this.renderImageURLInput()}
        {value?.imageType === imageTypes.URL &&
          this.renderLibraryImageURLInput()}
        {hasImage ? this.renderPlaceholderController() : null}
        {status ||
        imageType === imageTypes.UPLOADED ||
        value?.imageType === imageTypes.UPLOADED
          ? this.renderImageInput()
          : null}
        {canCrop && role !== 'listItem' ? this.renderImageCropping() : null}
      </div>
    )
  }
}

const mapStateToProps = (
  state,
  { objectId, value, role, reference, canBeUploaded }
) => {
  const appId = getCurrentAppId(state)
  const componentId = getComponent(state, objectId)?.id
  const realValue = value?.type === 'imageBinding' ? value.binding : value
  const binding = typeof realValue === 'object' ? realValue : null
  let label

  if (binding) {
    label = getLabel(state, binding.source, appId, componentId)
  }

  if (
    (canBeUploaded && value === 'uploaded') ||
    value?.imageType === imageTypes.UPLOADED
  ) {
    label = 'Uploaded Image'
  }

  if (value === 'url') {
    label = 'URL'
  }

  const options =
    isRole(role, 'listItem') && reference
      ? getListChildBindings({
          state,
          appId,
          componentId,
          objectId,
          reference,
          allowedDataTypes: [dataTypes.IMAGE],
        })
      : getLibraryBindingSuggestions(state, appId, componentId, objectId, [
          dataTypes.IMAGE,
        ])

  const selection = getSelection(state)
  const objects = selectObjects(state, selection)

  return {
    appId,
    componentId,
    label,
    options,
    datasources: getDatasources(state, appId),
    object: objects.length === 1 ? objects[0] : null,
  }
}

export default connect(mapStateToProps)(ImageControl)
