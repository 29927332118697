import BindingValue from 'components/Shared/BindingValue'
import { isFirefox } from 'utils/browsers'
import { normalizeColor } from 'utils/colors'
import { getFontFamily } from 'utils/type'
import { SocialMediaListSectionProps } from '.'

const BottomText = (props: SocialMediaListSectionProps): JSX.Element => {
  const { object, branding, widthScaled, sectionTop, zoom } = props
  const { attributes } = object
  const { bottomText, background } = attributes
  const { enabled, leftText, rightText, styles: bottomTextStyles } = bottomText
  const { leftText: leftTextStyles, rightText: rightTextStyles } =
    bottomTextStyles
  const { padding } = background
  if (!enabled) {
    return <></>
  }
  const leftTextX = (4 + padding) * zoom.scale

  return (
    <>
      {leftText && (
        <text
          x={0}
          y={0}
          fill={normalizeColor(leftTextStyles.color, branding)}
          fontSize={leftTextStyles.fontSize * zoom.scale}
          fontWeight={leftTextStyles.fontWeight}
          clipPath={`url(#bottom-left-text-${object.id})`}
        >
          <tspan
            alignmentBaseline="text-before-edge"
            x={leftTextX}
            y={
              sectionTop +
              3 -
              leftTextStyles.fontSize / 4 +
              (isFirefox() ? 5.5 : 0.5)
            }
            style={{
              fontFamily: getFontFamily(leftTextStyles.fontFamily, branding),
            }}
          >
            <BindingValue binding={leftText} objectId={object.id} />
          </tspan>
        </text>
      )}
      {rightText && (
        <text
          x={0}
          y={0}
          fill={normalizeColor(rightTextStyles.color, branding)}
          fontSize={rightTextStyles.fontSize * zoom.scale}
          fontWeight={rightTextStyles.fontWeight}
          clipPath={`url(#bottom-left-text-${object.id})`}
        >
          <tspan
            alignmentBaseline="text-before-edge"
            textAnchor="end"
            x={widthScaled - leftTextX}
            y={
              sectionTop +
              3 -
              rightTextStyles.fontSize / 4 +
              (isFirefox() ? 5.5 : 0.5)
            }
            style={{
              fontFamily: getFontFamily(rightTextStyles.fontFamily, branding),
            }}
          >
            <BindingValue binding={rightText} objectId={object.id} />
          </tspan>
        </text>
      )}
    </>
  )
}

export default BottomText
