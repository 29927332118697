import React, { Component } from 'react'
import {
  ACTION,
  dataTypes,
  controlTypes,
  SELECT,
  VIDEO,
} from '@adalo/constants'

// utils
import { capitalize } from 'utils/type'
import { hasRole } from 'utils/libraries'

// components
import FormulaControl from 'components/Shared/BindableTextControl/FormulaControl'
import { sanitization } from '@protonapp/react-entity-textarea'
import EntityTextControl from './EntityTextControl'
import TextControl from '../TextControl'
import ColorControl from './ColorControl'
import BooleanControl from './BooleanControl'
import InspectRow from '../Row'
import ActionRow from './ActionRow'
import IconRow from './IconRow'
import ImageControl from './ImageControl'
import VideoControl from './VideoControl'
import FileControl from './FileControl'
import MenuControl from './MenuControl'
import ListControl from './ListControl'
import AutosaveInputControl from './AutosaveInputControl'
import SliderControl from './SliderControl'
import HelpText from './HelpText'
import ConditionControl from './ConditionControl'
import { ShadowControl } from '../DefaultShadowControl'
import BorderControl from '../BorderControl'

export default class PropControl extends Component {
  handleChange = (val, actionChanges) => {
    const { onChange, namespace, prop } = this.props

    let changeObj = val || {}

    if (namespace) {
      changeObj = { [namespace]: { ...val } }
    }

    onChange(changeObj, actionChanges, prop.global)
  }

  renderControlSub() {
    const { prop, value, objectId } = this.props
    const { name, type } = prop

    if (type === dataTypes.BOOLEAN) {
      return (
        <BooleanControl
          name={name}
          onChange={this.handleChange}
          value={value}
          objectId={objectId}
        />
      )
    }

    return (
      <TextControl
        name={name}
        onChange={this.handleChange}
        value={value}
        placeholder={prop.default}
      />
    )
  }

  renderControl() {
    const {
      canBeUploaded,
      prop,
      value,
      values,
      objectId,
      role,
      reference,
      namespace,
      filename1x,
      mobileOnly,
      objectName,
      appId,
      topTitle,
      disableCurrentRecord = false,
      treatAsLibraryComponent,
    } = this.props

    let {
      name,
      displayName,
      editable,
      acceptedMimeTypes,
      allowPlaceholder = true,
      allowImageCropping = true,
      tooltip,
    } = prop

    if (editable === false) {
      return null
    }

    displayName = displayName || name

    const baseProps = {
      name,
      displayName,
      value,
      values,
      role,
      reference,
      objectId,
      onChange: this.handleChange,
      namespace,
      acceptedMimeTypes,
      mobileOnly,
      objectName,
      allowPlaceholder,
      allowImageCropping,
      tooltip,
      disableCurrentRecord,
      treatAsLibraryComponent,
    }

    let listControl = {
      filter: true,
      sort: true,
      limit: true,
      advancedOptions: true,
    }

    switch (prop.control && prop.control.type) {
      case controlTypes.MENU:
        return <MenuControl {...baseProps} options={prop.control.options} />
      case controlTypes.SLIDER:
        return (
          <SliderControl
            name={name}
            defaultValue={prop.defaultValue}
            onChange={this.handleChange}
            value={value}
            title={displayName || capitalize(name)}
            max={prop.control.max}
            min={prop.control.min}
            sliderBackground={prop.style}
          />
        )
      case controlTypes.SHADOW:
        return (
          <ShadowControl
            object={{ shadow: value }}
            onChange={({ shadow: val }) => this.handleChange({ [name]: val })}
            name={displayName}
          />
        )
      case controlTypes.BORDER:
        return (
          <BorderControl
            appId={appId}
            key="border-control"
            object={{ ...(value ?? {}) }}
            boxedToggle
            onChange={val => this.handleChange({ [name]: val })}
          />
        )
    }

    if (hasRole(prop, 'autosaveInput')) {
      return <AutosaveInputControl {...baseProps} dataType={prop.type} />
    }

    switch (prop.type) {
      case dataTypes.TEXT: {
        const disableMagicText = prop?.global || prop?.disableDynamicValues
        const libraryEditStyles = prop.styles

        return (
          <EntityTextControl
            {...baseProps}
            sanitization={prop.html ? sanitization.IGNORE : null}
            disableMagicText={disableMagicText}
            placeholder={prop.placeholder || 'Enter Text...'}
            libraryEditStyles={libraryEditStyles}
          />
        )
      }
      case dataTypes.NUMBER:
      case dataTypes.DATE:
      case dataTypes.DATE_ONLY:
        return (
          <FormulaControl {...baseProps} placeholder="0" dataType={prop.type} />
        )
      case dataTypes.IMAGE:
        return (
          <ImageControl
            {...baseProps}
            canBeUploaded={canBeUploaded}
            role={role}
            reference={reference}
            objectId={objectId}
            placeholder="Select image..."
            filename1x={filename1x}
          />
        )
      case VIDEO:
        return (
          <VideoControl
            {...baseProps}
            thumbnailControl={prop.thumbnailControl}
          />
        )
      case dataTypes.FILE:
        return (
          <FileControl
            {...baseProps}
            canBeUploaded={canBeUploaded}
            role={role}
            reference={reference}
            objectId={objectId}
            placeholder="Select image..."
            filename1x={filename1x}
          />
        )
      case dataTypes.LIST:
      case SELECT:
        if (prop.listControl) {
          listControl = { ...listControl, ...prop.listControl }

          return (
            <ListControl
              {...baseProps}
              objectId={objectId}
              listControl={listControl}
            />
          )
        } else {
          return (
            <ListControl
              {...baseProps}
              objectId={objectId}
              listControl={listControl}
            />
          )
        }
      case ACTION:
        return (
          <ActionRow
            {...baseProps}
            objectId={objectId}
            role={role}
            reference={reference}
          />
        )
      case dataTypes.ICON:
        return <IconRow {...baseProps} />
      case dataTypes.COLOR:
        return (
          <ColorControl {...baseProps} values={values} topTitle={topTitle} />
        )
      case dataTypes.CONDITION:
        return (
          <ConditionControl
            {...baseProps}
            binding={value}
            objectId={objectId}
          />
        )
      case prop.style === true && dataTypes.BOOLEAN:
        return (
          <BooleanControl
            {...baseProps}
            boxed={prop.style}
            displayName={displayName}
          />
        )
      default:
        return (
          <InspectRow
            title={displayName || capitalize(name)}
            className="library-inspect-panel-row"
          >
            {this.renderControlSub()}
          </InspectRow>
        )
    }
  }

  render() {
    const {
      prop: { helpText },
    } = this.props

    return (
      <>
        {this.renderControl()}
        {helpText ? <HelpText text={helpText} /> : null}
      </>
    )
  }
}
