import {
  ELLIPSE,
  LABEL,
  SHAPE,
  IMAGE,
  FORM,
  COMPONENT_INSTANCE,
  LIBRARY_COMPONENT,
  SECTION,
  INPUT,
  IMAGE_UPLOAD,
  FILE_UPLOAD,
  DATE_PICKER,
  SELECT,
  WEB_VIEW,
  LIST,
  LINE,
  LOCATION_INPUT,
  LAYOUT_SECTION,
  VIDEO,
  TABLE,
  SOCIAL_MEDIA_LIST,
} from '@adalo/constants'

import './tools.css'

export const RECTANGLE = Symbol('RECTANGLE')
export const OVAL = Symbol('OVAL')
export const TEXT = Symbol('TEXT')
export const VECTOR = Symbol('VECTOR')
export const UPLOAD = Symbol('UPLOAD')
export const ADD_COMPONENT = Symbol('ADD_COMPONENT')
export const ADD_LIBRARY_COMPONENT = Symbol('ADD_LIBRARY_COMPONENT')
export const DRAGGABLE = Symbol('DRAGGABLE')

export const tools = [
  RECTANGLE,
  OVAL,
  TEXT,
  VECTOR,
  UPLOAD,
  ADD_COMPONENT,
  ADD_LIBRARY_COMPONENT,
  DRAGGABLE,
]

export const isValidTool = tool => {
  return tools.includes(tool)
}

export const getTool = objectType => {
  switch (objectType) {
    case SHAPE:
      return VECTOR
    case COMPONENT_INSTANCE:
      return ADD_COMPONENT
    case LIBRARY_COMPONENT:
      return ADD_LIBRARY_COMPONENT
    case SECTION:
    case LAYOUT_SECTION:
    case LINE:
    case ELLIPSE:
    case IMAGE:
    case VIDEO:
    case LABEL:
    case INPUT:
    case IMAGE_UPLOAD:
    case FILE_UPLOAD:
    case DATE_PICKER:
    case SELECT:
    case WEB_VIEW:
    case TABLE:
    case FORM:
    case LIST:
    case SOCIAL_MEDIA_LIST:
    case LOCATION_INPUT:
      return DRAGGABLE
    default:
      return RECTANGLE
  }
}

export const getCursorClass = tool => {
  switch (tool) {
    case RECTANGLE:
    case OVAL:
    case UPLOAD:
      return 'cursor-crosshair'
    case LOCATION_INPUT:
    case TEXT:
      return 'cursor-text'
    case ADD_COMPONENT:
      return 'cursor-move'
    default:
      return 'default'
  }
}
