import { SOCIAL_MEDIA_LIST } from '@adalo/constants'

import { SocialMediaListObject } from 'utils/responsiveTypes'

import LayoutContext from './layout/LayoutContext'
import LayoutObject from './layout/LayoutObject'
import ObjectNode from './ObjectNode'

// Note: Any unexplained numbers exist for padding purposes. We have a padding slider from 1-10,
// however, in practice those usually end up rendering as roughly 5-15 or 10-20, depending on
// the area. This is to provide a range of options while ensuring that the content looks nice
// at every level.
const getHeight = (object: SocialMediaListObject, width: number): number => {
  const { attributes } = object
  const { bottomText, buttons, background, content } = attributes
  const { padding } = background
  const { styles: contentStyles } = content
  const { topCaption: topCaptionStyles } = contentStyles
  const { enabled: bottomTextEnabled, styles: bottomTextStyles } = bottomText
  const { enableBottomButton } = buttons

  // When this code is run, the zoom level is always 0.5
  const zoom = 0.5
  const aspectRatio = 3 / 4

  // 38 is the height of the image (30px) plus 4 px of padding on each side, so
  // that the total padding ranges from 5 to 14px, with the default being 10
  const headerHeight = (38 + 2 * padding) * zoom

  const contentTop =
    headerHeight + (topCaptionStyles.fontSize + 18 + padding) * zoom

  const contentHeight = width * aspectRatio
  const bottomTextTop = contentTop + (contentHeight + 6 + padding) * zoom

  const bottomTextMaxHeight = Math.max(
    bottomTextStyles.leftText.fontSize,
    bottomTextStyles.rightText.fontSize
  )

  const buttonsTop = bottomTextEnabled
    ? bottomTextTop + (bottomTextMaxHeight + 9 + padding) * zoom
    : bottomTextTop

  let listItemHeight = buttonsTop + (28 + padding) * zoom

  if (enableBottomButton) {
    listItemHeight += 25 + padding * zoom
  }

  return listItemHeight
}

export default class SocialMediaListNode extends ObjectNode<
  typeof SOCIAL_MEDIA_LIST
> {
  private readonly list: SocialMediaListObject

  constructor(id: string, list: SocialMediaListObject) {
    super(id, SOCIAL_MEDIA_LIST)

    this.list = list
  }

  public override layout(
    context: LayoutContext
  ): LayoutObject<typeof SOCIAL_MEDIA_LIST> {
    const objectLayout = super.layout(context)

    const { width, left, right } = objectLayout

    const height = getHeight(this.list, width) * 2

    return {
      id: objectLayout.id,
      type: objectLayout.type,
      x: objectLayout.x,
      y: objectLayout.y,
      children: [],
      height,
      width,
      left,
      right,
    }
  }
}
