import { Binding } from '@adalo/constants'
import BindingValue from 'components/Shared/BindingValue'
import { isFirefox } from 'utils/browsers'
import { Zoom } from 'utils/canvasTypes'
import { normalizeColor } from 'utils/colors'
import { Branding } from 'utils/colorsTypes'
import { getFontFamily } from 'utils/type'
import { scaleValue } from 'utils/zoom'
import Rect from '../Section/Rect'

type Props = {
  enableBottomButton: boolean
  widthScaled: number
  zoom: Zoom
  branding: Branding
  id: string
  sectionTop: number
  padding: number
  bottomButtonColor: string
  bottomButtonTextStyles?: {
    color: string
    fontSize: number
    fontWeight: number
    fontFamily: string
  }
  bottomButtonText?: string | [string | Binding] | undefined
}

export const BottomButton = (props: Props): JSX.Element | null => {
  const {
    enableBottomButton,
    widthScaled,
    zoom,
    branding,
    id,
    sectionTop,
    padding,
    bottomButtonColor,
    bottomButtonTextStyles,
    bottomButtonText,
  } = props

  const {
    color = '@contrast:bottomButtonColor',
    fontSize = 14,
    fontWeight = 400,
    fontFamily = 'inherit',
  } = bottomButtonTextStyles || {}

  if (!enableBottomButton) {
    return null
  }

  return (
    <>
      <Rect
        width={widthScaled * 0.6}
        height={35}
        widthScaled={widthScaled * 0.6}
        heightScaled={scaleValue(35, zoom)}
        zoom={zoom}
        borderRadius={18}
        borderTopLeftRadius={18}
        borderTopRightRadius={18}
        borderBottomLeftRadius={18}
        borderBottomRightRadius={18}
        strokeWidth={0}
        stroke="transparent"
        x={widthScaled * 0.2}
        y={sectionTop + 14 + padding / 2}
        fill={normalizeColor(bottomButtonColor, branding)}
        fillOpacity={1}
        className="social-media-list-background"
      />
      {bottomButtonText ? (
        <text
          x={0}
          y={0}
          fill={normalizeColor(color, branding, {
            bottomButtonColor,
          })}
          clipPath={`url(#bottom-caption-path-${id})`}
          fontSize={fontSize / 2}
          fontWeight={fontWeight}
        >
          <tspan
            alignmentBaseline="text-before-edge"
            x={widthScaled * 0.5}
            y={
              sectionTop +
              22 +
              padding / 2 -
              fontSize / 4 +
              (isFirefox() ? 7 : 0)
            }
            style={{
              fontFamily: getFontFamily(fontFamily, branding),
            }}
            textAnchor="middle"
          >
            <BindingValue binding={bottomButtonText} objectId={id} />
          </tspan>
        </text>
      ) : null}
    </>
  )
}
